export const menuItems = [
  {
    name: "/",
    label: "Home",
  },
  {
    name: "/about",
    label: "About",
  },
  {
    name: "/contact",
    label: "Contact",
  },
];
